<template>
	<div class="page-content">
		<div class="park-form">
			<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
				<el-form-item label="日期" prop="authUserName">
					<el-date-picker clearable style="width: 220px;" v-model="selectDate" type="daterange"
						format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" @change="selectedDate" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item label="支付状态" prop="s_phone">
					<el-select v-model="searchForm.status" clearable placeholder="支付状态">
						<el-option label="待支付" :value='1'></el-option>
						<el-option label="已支付" :value='2'></el-option>
						<el-option label="已退款" :value='3'></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="订单状态" prop="is_check">
					<el-select v-model="searchForm.is_check" clearable placeholder="审核状态">
						<el-option label="已过期" :value='2'></el-option>
						<el-option label="已核销" :value='1'></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="用户电话" prop="phone">
					<el-input v-model="searchForm.phone" style="width: 220px;" clearable placeholder="用户电话"></el-input>
				</el-form-item>

				<el-form-item style="margin-top: auto;margin-right: 0;">
					<div class="fill-btn" @click="orderList">查询</div>
				</el-form-item>

				<el-form-item style="margin-top: auto;margin-left:10px;">
					<div class="fill-btn" @click="addOrders">创建订单</div>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-left:0; margin-right: auto;">
					<el-button type="warning" @click="excelTeamOrder">导出</el-button>
				</el-form-item>
				<!--<el-form-item style="margin-top: auto;margin-left: auto;">-->
				<!--<div>奖励总积分：<strong style="color: #ff4444;font-size: 20px">{{allPrice}}</strong></div>-->
				<!--</el-form-item>-->

			</el-form>
			<div style="display: flex;flex-wrap: wrap;padding-bottom: 20px;">
				<div>
					<div>代金券金额：{{this.price.all_coupons}}</div>
				</div>
				<div style="margin-left: 20px;">
					<div>店铺金额：{{this.price.all_store}}</div>
				</div>
				<div style="margin-left: 20px;">
					<div>折扣金额：{{this.price.all_platform}}</div>
				</div>
				<div style="margin-left: 20px;">
					<div>退款金额：{{this.price.refund_price_all}}</div>
				</div>
			</div>
		</div>

		<div class="table-content">
			<div class="table-top">
				<div class="table-top-text">
					<!--已选择{{selectData.length}}项-->
				</div>
			</div>
			<el-table :data="tableData" stripe v-loading="loading" @selection-change="handleSelectionChange"
				cell-class-name="text-center" header-cell-class-name="header-row-class-name">
				<el-table-column prop="created_at" label="下单日期" width="148"></el-table-column>
				<el-table-column prop="number" label="订单号" min-width="120px"></el-table-column>
				<!-- <el-table-column prop="room_number" label="房间编号" width="90"></el-table-column> -->
				<el-table-column prop="phone" label="用户" min-width="120px"></el-table-column>
				<!-- <el-table-column prop="date" label="日期" width="100px"></el-table-column>
				<el-table-column prop="phone" label="预定时间" width="90px">
					<template slot-scope="{row}">
						<div class="btn-content" v-if="row.re_satrt&&row.re_end">
							<el-tag> {{row.re_satrt}}-{{row.re_end}}</el-tag>
						</div>
					</template>
				</el-table-column> -->
				<el-table-column prop="name" label="店铺" min-width="120px"></el-table-column>
				<el-table-column prop="title" label="主题" min-width="120px"></el-table-column>
				<el-table-column prop="num" label="人数" width="80"></el-table-column>
				<el-table-column prop="price" label="单价" width="80"></el-table-column>
				<el-table-column prop="pay_price" label="店铺收益" width="80"></el-table-column>
				<el-table-column prop="scale_price" label="折扣金额" width="80"></el-table-column>
				<el-table-column prop="coupons_price" label="代金券金额" width="90"></el-table-column>
				<el-table-column width="80" label="支付">
					<template slot-scope="{row}">
						<div class="btn-content">
							<el-tag v-if="row.status == 1" round>待支付</el-tag>
							<el-tag v-else-if="row.is_refund == 1&&row.status == 2" type="success" round>已支付</el-tag>
							<!-- warning -->
							<el-tag v-else-if="row.is_refund == 2" type="danger" round>已退款</el-tag>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column width="80" label="店铺收益">
					<template slot-scope="{row}">
						<div class="btn-content">
							<el-tag v-if="row.is_check == 2" round>0</el-tag>
							<el-tag v-if="row.is_check == 1" type="success" round>{{row.store_price}}</el-tag>
						</div>
					</template>
				</el-table-column>

				<el-table-column v-if="this.user_type == 1" prop="platform_price" label="平台收益" width="80">
				</el-table-column> -->
				<!-- <el-table-column width="200">
                    <template slot-scope="{row}">
                        <div class="btn-content" v-if="row.status==2">
                            <el-button class=" cur-p"  v-if="row.is_check != 1" type="primary" size="mini"
                                       @click="saveOrder(row)"> 核销</el-button>
                            <el-button class=" cur-p"  v-if="row.is_dispose == 1" type="primary" size="mini"
                                       @click="saveOrderDispose(row)">待处理</el-button>
                            <el-button class=" cur-p "  v-if="row.is_dispose == 2" type="warning" size="mini"
                                       @click="save(row)">已处理</el-button>
                        </div>
                    </template>
                </el-table-column> -->
				<el-table-column width="100">
                    <template slot-scope="{row}">
                        <div class="btn-content" v-if="row.is_refund==1&&row.status == 2">
                            <el-button class="cur-p" type="primary" size="mini"
                                       @click="showRefund(row)">退款</el-button>
                        </div>
                    </template>
                </el-table-column>
			</el-table>
			<div class="pagination-content">
				<el-pagination background :current-page.sync="searchForm.page" :page-size="searchForm.limit"
					@current-change="searchForm.page=$event" @size-change="handleSizeChange"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
			<el-dialog title="创建订单" :visible.sync="order">
				<div style="padding: 20px;">
					<el-form ref="forms" :model="addOrder" :rules="getRules" label-width="80px">
						<el-form-item label="用户" prop="phone">
							<el-input v-model="addOrder.phone" style="width: 220px;" clearable placeholder="请输入用户号码">
							</el-input>
						</el-form-item>
						<el-form-item label="门店" prop="shop_id" v-if="!userInfo.store_id">
							<el-select v-model="addOrder.shop_id" @change="getThemeLists(addOrder.shop_id)" filterable
								remote reserve-keyword placeholder="请选择门店" :remote-method="getStoreAll"
								:loading="selLoading">
								<el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="主题" prop="themes_id">
							<el-select v-model="addOrder.themes_id" @change="getTimeList(addOrder.themes_id)" clearable
								placeholder="请选择主题">
								<el-option v-for="item in themeList" :key="item.id" :label="item.title"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<!-- <el-form-item label="时间" prop="room_id">
							<el-select v-model="addOrder.room_id" clearable placeholder="请选择时间">
								<el-option v-for="item in roomList" :key="item.id"
									:label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item>
							<el-button type="primary" :loading="editing" @click="createOrders"
								style="margin: 10px auto">提交</el-button>

						</el-form-item>
					</el-form>
				</div>
			</el-dialog>
			<el-dialog title="退款" :visible.sync="refundShow">
				<div style="padding: 20px;">
					<el-form ref="form" :model="form" :rules="rules" label-width="80px">
						<el-form-item label="退款金额" prop="refund_price">
							<el-input v-model="form.refund_price" style="width: 220px;" clearable placeholder="请输入退款金额">
							</el-input>
						</el-form-item>
						<el-form-item label="">
							支付金额￥{{pay_price}}
						</el-form-item>
						<el-form-item>
							<el-button @click="refundShow=false" style="margin: 10px auto">取消</el-button>	
							<el-button type="primary" :loading="editing" @click="refundOrder"
								style="margin: 10px">提交</el-button>
			
						</el-form-item>
					</el-form>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		delToast,
		formVerify
	} from './../../utlis/decorators'
	import RichText from './../../components/RichText'

	export default {
		name: "tenant_account",
		props: {
			parkId: {
				type: [Number, String],
				default: null
			},
		},
		components: {
			RichText
		},
		data() {
			return {
				order: false,
				addOrder: {
					shop_id: '',
					room_id: '',
					phone: '',
					themes_id: '',
				},
				order_id: '',
				hxphone: false,
				order_n: '',
				dialogFormVisible: false,
				loading: false,
				currentTop: 0,
				total: 0,
				selectDate: [],
				showAdd: false,
				showEdit: false,
				showEditPsd: false,
				editing: false,
				searchForm: {
					page: 1,
					limit: 10,
					username: '',
					name: '',
					type: '',
					phone: '',
					amount: ''

				},
				form: {
					nickname: ''
				},
				editForm: {
					role_ids: [],
					nickname: '',
					username: '',
					password: '',
					status: ''
				},
				currentId: '',
				tableData: [],
				typeList: [],
				addTeacher: {},
				allPrice: 0,
				all: [],
				amount: [],
				rate: [],
				year: [],
				company: [],
				user_type: '',
				price: {
					all_coupons: 0,
					all_store: 0,
					all_platform: 0,
				},
				themeList: [],
				timeList: [],
				storeList: [],
				
				refundShow:false,
				form:{
					id:'',
					is_refund:'',//  是否退款   1  否   2   是   
					refund_price:'',//  退款金额
				},
				pay_price:'',
			}
		},
		created() {
			this.orderList();
			this.getStoreAll();
			if(this.userInfo.store_id){
				this.getThemeLists(this.userInfo.store_id)
			}
			this.user_type = window.sessionStorage.getItem('type')
		},
		computed: {
			getRules() {
				return {
					phone: [{
						required: true,
						message: '用户不能为空',
						trigger: ['blur', 'change']
					}],
					shop_id: [{
						required: true,
						message: '请选择门店',
						trigger: ['blur', 'change']
					}],
					themes_id: [{
						required: true,
						message: '请选择主题',
						trigger: ['blur', 'change']
					}],
					room_id: [{
						required: true,
						message: '请选择时间',
						trigger: ['blur', 'change']
					}],
				}
			},
			rules() {
				return {
					refund_price: [{
						required: true,
						message: '请输入退款金额',
						trigger: ['blur', 'change']
					}],
				}
			},
			userInfo() {
				return this.$store.state.user.userInfo
			},
			roomList() {
				return this.timeList.reduce((ary,item)=>{
					if(item.num==item.life_num){
						item.disabled = true;
					}
					item.surplus = item.num - item.life_num 
					item.name = item.start_time +'-'+item.end_time + '（剩余' + item.surplus+ '人）';
					ary.push(item)
					return ary
				},[])
			}
		},
		watch: {
			'searchForm.page'() {
				this.orderList();
			},
		},
		methods: {
			//获取店铺
			async getStoreAll(name) {
				this.selLoading = true
				let [e, data] = await this.$api.searchStoreListApi({
					page: 1,
					limit: 50,
					name
				});
				this.selLoading = false
				if (e) return;
				if (data.code === 200) {
					this.storeList = data.data.data;
				}
			},
			//获取主题
			async getThemeLists(store_id) {
				let [e, data] = await this.$api.themeLists({
					store_id
				});
				if (e) return
				if (data.code === 200) {
					this.themeList = data.data;
					if (this.addOrder.themes_id) {
						let index = this.themeList.findIndex(item => {
							return item.id == this.addOrder.themes_id
						})
						console.log(index)
						if (index == -1) {
							this.addOrder.themes_id = '';
							this.addOrder.room_id = '';
							this.timeList = [];
						}
					}
					
				}
			},
			//获取预定信息
			async getTimeList(id) {
				return
				let [e, data] = await this.$api.searchStoreRoomListApi({
					theme_id: id,
					store_id: this.addOrder.shop_id
				});
				if (e) return;
				if (data.code === 200) {
					this.addOrder.room_id = '';
					this.timeList = data.data;
				}
			},
			//头像
			handleAvatarSuccess(res, file) {

				this.editForm.head = res.url;
				this.addTeacher.head = res.url;
				console.log(this.addTeacher.head);
			},
			// 选择日期
			selectedDate(e) {
				if (e) {
					this.searchForm.start_time = e[0];
					this.searchForm.end_time = e[1];
					console.log(this.searchObj);
				} else {
					this.searchForm.start_time = '';
					this.searchForm.end_time = '';
				}
			},

			//公司列表
			async companyList() {

				let [e, data] = await this.$api.companyList();

				if (data.code === 200) {
					this.company = data.data;
				}
			},
			//类型
			async getTeacherTypeList() {
				let [e, data] = await this.$api.ruleList();
				if (e) return;
				console.log(data);
				if (data.code === 200) {
					this.typeList = data.data;
				}
			},
			//类型
			async getSearch() {
				let [e, data] = await this.$api.orderAmount();
				if (e) return;
				console.log(data);
				if (data.code === 200) {
					this.amount = data.amount;
					this.rate = data.rate;
					this.year = data.year;
				}
			},
			//产品列表
			async getProductList() {
				let [e, data] = await this.$api.productAll(this.searchForm);
				if (e) return;
				if (data.code === 200) {
					console.log(data);
					this.all = data.data;
					// this.total = data.data.total;
				}
			},
			//判断显示是否退款
			async showRefund(row){
				this.pay_price = row.pay_price;
				this.form.is_refund = 2;
				this.form.refund_price = row.pay_price;
				this.form.id = row.id;
				this.refundShow = true;
			},
			//退款
			@formVerify('form')
			async refundOrder(){
				this.editing = true;
				let [e, data] = await this.$api.refundOrder(this.form);
				if (e) {
					this.editing = false;
					return
				};
				if (data.code === 200) {
					this.refundShow = false;
					this.$message.success('提交成功！');
				}
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG,png 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}

			},
			handShowAdd() {
				this.editForm = [];
				this.editForm.nickname = '';
				this.editForm.username = '';
				this.editForm.password = '';

				this.showAdd = true;
			},
			//核销
			handEditShow(row) {
				this.order_id = row.id;
				this.hxphone = true;
			},
			//订单核销
			async saveOrder({
				id
			}) {
				let [e, data] = await this.$api.orderCheck({
					id: id
				});
				this.editing = false;
				if (e) return;
				if (data.code === 200) {
					this.hxphone = false;
					this.orderList();
					this.$message.success('成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},
			//订单处理
			async saveOrderDispose({
				id
			}) {
				let [e, data] = await this.$api.ordersaveDispose({
					id: id
				});
				this.editing = false;
				if (e) return;
				if (data.code === 200) {
					this.hxphone = false;
					this.orderList();
					this.$message.success('成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},

			//创建订单
			async addOrders() {
				try {
					this.order = true;
					this.$refs['forms'].resetFields()
					for (let i in this.addOrder) {
						this.addOrder[i] = '';
					}
					this.addOrder.shop_id = this.userInfo.store_id || '';
				} catch {}

			},
			//创建订单
			@formVerify('forms')
			async createOrders() {
				this.editing = true
				let [e, data] = await this.$api.createdOrder(this.addOrder);
				this.editing = false
				if (e) return;
				if (data.code === 200) {
					this.order = false;
					this.orderList();
					this.$message.success('成功')
				}
			},

			//页面数量改变
			handleSizeChange(val) {
				this.searchForm.page = 1;
				this.searchForm.limit = val;
			},
			handleSelectionChange(val) {
				this.selectData = val;
			},
			//获取分销列表
			async orderList() {
				this.loading = true;
				let [e, data] = await this.$api.orderList(this.searchForm);
				this.loading = false;
				if (e) return;
				if (data.code === 200) {
					this.tableData = data.data.data.data;
					this.price = data.data.price;
					this.total = data.data.data.total;
				}
			},

			//导出
			async excelTeamOrder() {
				this.$downFile('OrderExport', this.searchForm, '订单');
			},
		},
	}
</script>
<style scoped lang="less">
	.page-content {
		width: calc(~'100% - 60px');
		margin: 26px 30px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
		padding: 20px 30px;

		.park-form {
			border-bottom: 1px solid #efefef;
		}

		.table-content {
			padding-top: 20px;

			.table-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.table-top-text {
					font-size: 16px;
					font-weight: 400;
					color: rgba(35, 39, 52, 1);
				}

				.table-top-btn {}
			}
		}
	}

	.btn-content {
		display: flex;
		justify-content: center;
		align-items: center;

		.iconfont {
			font-size: 20px;
			color: rgb(190, 199, 213);
			margin: 0 10px;
			cursor: pointer;
		}

		.icon-shanchu {
			font-size: 24px;
		}
	}

	.box-shadow {
		width: calc(~'100% + 40px');
		height: 50px;
		position: absolute;
		top: 0;
		left: -20px;
		z-index: 10;
		box-shadow: 0 0 4px #b4b4b4;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
